export default {
  methods: {
    /**
     * @param name String
     * @param count Number|Boolean
     * @returns {*}
     */
    getTranslation(name, count = false) {
      // eslint-disable-next-line no-prototype-builtins
      if (this.translations.hasOwnProperty(name)) {
        if (count !== false && Array.isArray(this.translations[name])) {
          if (count === 1) {
            return this.translations[name][0];
          }

          if (count > 1 && count < 5) {
            return this.translations[name][1];
          }

          if (count === 0 || count > 0) {
            return this.translations[name][2];
          }
        } else {
          return this.translations[name];
        }
      }
      return name;
    },
  },
};
