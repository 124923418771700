<template>
  <div class="tabs">
    <Tabs
      :tabs="tabs"
      :defaultIndex="activeTab"
      @onChange="onTabChange"
    />

    <ul class="checkbox-list">
      <li
        v-for="country in sortedVisibleCountries"
        :key="country.id"
        class="checkbox-list__item">
        <Checkbox
          name="countries"
          :id="country.id"
          :checked="selectedCountries.includes(country.id)"
          :value="country.id"
          :label="country.name"
          :flag="country.iso.toLowerCase()"
          :disabled="country.$isDisabled"
          @onToggle="onCountryChange"
        />
      </li>
    </ul>
  </div>
</template>

<script>
/**
 * @emits onTabChange when tab changes
 * @emits onCountryChange new country is selected or deselected
 */
import Tabs from "./Tabs.vue";
import Checkbox from "./Checkbox.vue";

export default {
  name: "country-selection",

  components: {
    Checkbox,
    Tabs,
  },

  props: {
    /** list of country categories */
    visibleCountries: {
      type: Array,
      require: true,
    },

    /** list of selected countries define in url  */
    selectedCountries: {
      type: Array,
      require: true,
    },
    /** list of tabs */
    countryCategories: {
      type: Array,
      require: true,
    },
    activeTab: {
      type: Number,
      require: true,
    },
  },
  computed:{
    sortedVisibleCountries: function(){

      var charMapL = " 0123456789aábcčdďeéěfghiíjklmnňoópqrřsštťuúůvwxyýzž";
      var charMapU = " 0123456789AÁBCČDĎEÉĚFGHIÍJKLMNŇOÓPQRŘSŠTŤUÚŮVWXYÝZŽ";
      var charsOrder = {};
      for(var i in charMapL.split('')) {
        charsOrder[charMapL[i]] = parseInt(i);
        charsOrder[charMapU[i]] = parseInt(i);
      }


      return this.visibleCountries.sort((a, b)=>{
        var idx = 0;
        var s1 = a.name;
        var s2 = b.name;

        while ( (idx < s1.length) && (idx < s2.length) && (charsOrder[s1[idx]] === charsOrder[s2[idx]])) {
          idx ++;
        }
        if ((idx === s1.length) && (idx === s2.length)) return 0;
        if (idx === s1.length) return 1;
        if (idx === s2.length) return -1;
        return charsOrder[s1[idx]] > charsOrder[s2[idx]] ? 1 : (charsOrder[s1[idx]] < charsOrder[s2[idx]] ? -1 : 0);

      });
    }
  },
  data() {
    const tabs = this.countryCategories.map((item) => item.name);

    return {
      tabs,
    };
  },

  methods: {
    onTabChange(value) {
      this.$emit("onTabChange", value);
    },

    onCountryChange(_, code) {
      this.$emit("onCountryChange", code);
    },
  },
};
</script>

<style scoped lang="less">
@import "../less/variables.less";
/**
 Tabs
*/
.tabs {
  margin-bottom: @spacer * 2;
}

.checkbox-list {
  margin: (@spacer * .75) 0 0;
  padding: 0;
  min-height: 40px;
  list-style: none;

  @media (min-width:768px) {
    column-count: 2;
  }

  @media (min-width:992px) {
    column-count: 4;
  }
}

.checkbox-list__item {
  margin-bottom: @spacer * .5;
}
</style>
