<template>
  <section>
    <Table
      v-for="(table, index) in generalTables"
      :key="index"
      :data="table"
      :translations="translations"
    >
      <template slot="header">
        <h2
          class="title l-title-custom"
          v-html="table.subHeader"
        />
      </template>
      <template
        slot="legend"
        v-if="index === 0"
      >
        <div class="legend">
          <div class="legend__item">
            <i class="icon is-success icon-checkmark-circle"/>
            <span v-html="getTranslation('legend_yes')"/>
          </div>
          <div class="legend__item">
            <i class="icon is-warning icon-info-circle"/>
            <span v-html="getTranslation('legend_maybe')"/>
          </div>
          <div class="legend__item">
            <i class="icon is-error icon-close-circle"/>
            <span v-html="getTranslation('legend_no')"/>
          </div>
        </div>
      </template>
    </Table>
  </section>
</template>

<script>
import Table from "./Table.vue";
import { createTableContent } from "../helpers/tables";
import getTranslation from "../mixins/translate";

export default {
  name: "GeneralTables",
  components: {
    Table,
  },
  props: {
    translations: {
      type: Object,
      required: true,
    },
    tables: {
      type: Array,
      required: true,
    },
    countries: {
      type: Array,
      required: true,
    },
    restrictions: {
      type: Array,
      required: true,
    },
    selectedCountries: {
      type: Array,
      required: true,
    },
  },
  mixins: [ getTranslation ],
  computed: {
    generalTables() {
      if (!this.tables) {
        return [];
      }
      const countries = this.selectedCountries
        .map((code) => this.countries.find((item) => item.id === code));

      return this.tables
        .map((table, index) => createTableContent(table, countries, this.restrictions, index > 0));
    },
  },
};
</script>

<style scoped>

</style>
