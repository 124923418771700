<template>
  <div class="switch-filter switchers">
    <Button
      v-for="option in options"
      :key="option.value"
      :selected="selectedOptions.includes(option.value)"
      :disabled="option.isDisabled"
      :color="!selectedOptions.includes(option.value) ? 'gray' : undefined"
      size="sm"
      class="swither-filter-btn"
      @onClick="onChangeSelected(option.value)"
    >
      {{ option.label }}
    </Button>
  </div>
</template>

<script>
/**
 * @emits onChange when selected value changes
 */
import Button from "./Button.vue";

export default {
  name: "SwitchFilter",
  components: {
    Button,
  },

  props: {
    options: {
      type: Array,
      required: true,
    },
    selectedOptions: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  methods: {
    onChangeSelected(value) {
      this.$emit("onChange", value);
    },
  },
};
</script>

<style scoped lang="less">
@import "../less/variables";

.switch-filter {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.swither-filter-btn {
  margin: 0 (@spacer * .5) @spacer;
}

.switchers {
  margin-bottom: @spacer;
}

</style>
