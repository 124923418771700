<template>
  <div class="content">
    <slot name="header"/>
    <div
      class="wrapper"
      v-if="data"
    >
      <table class="table">
        <thead>
          <tr>
            <th>{{ data.headerRow.title }}</th>
            <th
              v-for="(item, index) in data.headerRow.items"
              :key="index"
            >
              <a :href="item.url">{{ item.label }}</a>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="icons"
            v-for="(row, rowIndex) in data.bodyRows"
            :key="rowIndex"
          >
            <td
              v-for="(cell, cellIndex) in row"
              :key="cellIndex"
            >
              <span v-if="!cell.icon">{{ cell.label }}</span>
              <i
                v-if="cell.icon"
                :class="cell.icon"
                :title="cell.label"
              />
            </td>
          </tr>
        </tbody>
        <tfoot v-if="data.footerRows">
          <tr
            v-for="(row, rowIndex) in data.footerRows"
            :key="rowIndex"
          >
            <td
              v-for="(cell, cellIndex) in row"
              :key="cellIndex"
            >
              <Button
                v-if="cell.label !== ``"
                size="sm"
                :href="cell.url"
                iconRight="icon-arrow-right"
                outlined
                v-html="cell.label || getTranslation('button_show_more')"
              />
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
    <slot name="legend"/>
  </div>
</template>

<script>
import Button from "./Button.vue";
import getTranslation from "../mixins/translate";

export default {
  name: "table-vue",
  components: {
    Button,
  },
  props: {
    translations: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      required: false,
      default: null,
    },
  },
  mixins: [ getTranslation ],
};
</script>

<style scoped lang="less">
@import "../less/variables";
@import "../less/mixins";

.title {
  .title-styles; // mixin for titles
}

.subtitle {
  margin: 0 0 @spacer;
  line-height: @line-height-05;
  font-size: @font-size-05;
  font-weight: @font-weight-normal;
}

.wrapper {
  overflow-x: auto;
  overflow-y: hidden;
}

.table {
  min-width: 100%;
  border-radius: @border-radius;
  line-height: @line-height-02;
  font-size: @font-size-02;
  font-weight: @font-weight-thin;
  border-collapse: collapse;
  table-layout: fixed;

  @media (min-width:768px) {
    line-height: @line-height-03;
    font-size: @font-size-03;
  }

  thead {
    th {
      padding: (@spacer * .25) @spacer;
      height: 48px;
      background-color: @gray-darker;
      font-weight: @font-weight-bold;
      color: @white;
      box-sizing: border-box;

      &:first-child {
        border-radius: @border-radius 0 0 0;
        background-color: @gray-darkest;
      }

      &:last-child {
        border-radius: 0 @border-radius 0 0;
      }
    }
  }

  tbody {
    tr {
      td {
        background-color: @white;
      }

      &:nth-child(even) {
        td {
          background-color: @gray-lighter;
        }
      }
    }

    td {
      &:first-child {
        font-weight: @font-weight-bold;
      }
    }
  }

  tfoot {
    td {
      padding: @spacer (@spacer * .25);
      border-top: 1px solid @gray-light;
      border-right: 0;

      &:first-child {
        box-shadow: none;
      }
    }
  }

  td,
  th {
    padding: (@spacer * .25) @spacer;
    min-width: 140px; // For Details button in footer
    height: 35px;
    border-right: 1px solid @gray-light;
    background-color: @white;
    text-align: center;
    box-sizing: border-box;

    &:first-child {
      position: sticky;
      left: 0;
      width: 110px;
      min-width: 110px;
      max-width: 110px;
      text-align: left;
      box-shadow: 1px 0 0 0 @gray-light;

      @media (min-width:768px) {
        width: 195px;
        min-width: 195px;
        max-width: 195px;
      }

      @media (min-width:992px) {
        width: 265px;
        min-width: 265px;
        max-width: 265px;
      }
    }

    &:last-child {
      border-right: 0;
      box-shadow: none;
    }

    a {
      color: inherit;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

/**
 Row with icons
 */
.icons {
  td {
    vertical-align: middle;
  }
}

.icon {
  vertical-align: middle;
  font-size: 20px;

  &.is-success {
    color: @success;
  }

  &.is-warning {
    color: @warning;
  }

  &.is-error {
    color: @danger;
  }
}

/*
 Legend
 */
.legend {
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid @gray-light;
}

.legend__item {
  display: flex;
  align-items: center;
  margin-top: @spacer * .5;
  margin-right: @spacer * 1.5;
  line-height: @line-height-03;
  font-size: @font-size-03;
  font-weight: @font-weight-thin;

  .icon {
    margin-right: @spacer * .5;
  }
}
</style>
