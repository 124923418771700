<template>
  <div class="fshr-searchbar">
    <Multiselect
      @input="onInput"
      @open="onOpen"
      @close="onClose"
      class="fshr-multiselect"
      :multiple="true"
      v-model="localValue"
      :value="selectedOptions"
      :options="options"
      track-by="name"
      label="name"
      :placeholder="getTranslation('search_placeholder')"
      :selectLabel="getTranslation('search_selectLabel')"
      :deselectLabel="getTranslation('search_deselectLabel')"
      :selectedLabel="getTranslation('search_selectedLabel')"
    >
      <template
        slot="option"
        slot-scope="props"
      >
        <strong>
          <i :class="`flag-icon flag-icon-${props.option.iso}`"/>
          {{ props.option.name }}
        </strong>
      </template>
      <template
        slot="singleLabel"
        slot-scope="props"
      >
        {{ props.option.name }}
      </template>
    </Multiselect>

    <div
      v-if="isOpen"
      class="fshr-backdrop"
    />
  </div>
</template>

<script>
/**
 * @emits onChange when country is selected or deselected
 */
import Multiselect from "vue-multiselect";
import getTranslation from "../mixins/translate";

export default {
  name: "search-bar",
  components: {
    Multiselect,
  },
  props: {
    translations: {
      type: Object,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    selectedOptions: {
      type: Array,
      require: false,
      default: () => [],
    },
  },
  mixins: [ getTranslation ],
  data() {
    return {
      localValue: this.selectedOptions,
      isOpen: false,
    };
  },

  methods: {
    onOpen() {
      this.isOpen = true;
    },
    onClose() {
      this.isOpen = false;
    },
    onInput() {
      this.$emit("onChange", this.localValue);
    },
  },

  updated() {
    this.localValue = this.selectedOptions;
  },
};
</script>

<style lang="less">
/* Styles can't be scoped because of vue-multiselect */
@import "~vue-multiselect/dist/vue-multiselect.min.css";
@import "../less/variables.less";
@import "../less/mixins";

.fshr-searchbar {
  position: relative;
  margin-bottom: @spacer;
  z-index: 9;

  .multiselect {
    min-height: @multiselect_min-height;
    font-family: @font-family-regular;
    color: @black;
  }

  .multiselect,
  .multiselect__input,
  .multiselect__single {
    line-height: @line-height-03;
    font-size: @font-size-03;
  }

  .multiselect--disabled {
    background: @gray-light;
  }

  .multiselect__input,
  .multiselect__single {
    margin: (@spacer * .25) 0;
    padding: 0;
    max-width: calc(100% - 40px);
    border: 0;
    background-color: inherit;

    &::placeholder {
      color: @gray-darker;
    }
  }

  .multiselect__placeholder {
    margin: 0;
    padding: 0;
    color: @gray-darker;
  }

  .multiselect__select {
    display: none;
  }

  /* Tags */
  .multiselect__tags-wrap {
    display: flex;
    flex-wrap: wrap;
  }

  .multiselect__tags {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: (@spacer * .5) @spacer;
    min-height: @multiselect_min-height;
    border-radius: @border-radius;
    border-color: @gray-dark;

    &:hover {
      background-color: @gray-lighter;
    }

    /* Search icon */
    &::before {
      .iconfont-styles; // mixin for iconfont
      content: @icon-magnifying;
      margin-right: @spacer;
      font-size: @multiselect--tag_height;
      color: @black;
    }
  }

  .multiselect__tag {
    display: flex;
    align-items: center;
    margin: (@spacer * .375) @spacer (@spacer * .375) 0;
    padding: 0 (@spacer * 1.5) 0 (@spacer * .5);
    height: @multiselect--tag_height;
    background-color: @gray-darker;
    text-transform: uppercase;
    font-size: @font-size-00;
    font-weight: @font-weight-semibold;
  }

  .multiselect__tag-icon {
    line-height: @multiselect--tag_height;

    &::after {
      font-size: @font-size-03;
      color: @white;
      transition: .2s;
    }

    &:focus,
    &:hover {
      background: @gray-darker;

      &::after {
        color: @primary;
      }
    }
  }

  /* Dropdown */
  .multiselect__content-wrapper {
    margin-top: @spacer * .25;
    border: 0;
    border-radius: @border-radius;
  }

  .multiselect__element {
    border-top: 1px solid @gray-light;

    &:first-child {
      border-top: 0;
    }
  }

  /* Options */
  .multiselect__option {
    padding: @spacer;
    min-height: @multiselect_min-height;
    color: inherit;

    &::after {
      background-color: inherit;
      line-height: @multiselect_min-height;
      font-style: italic;
      font-weight: @font-weight-normal;
      color: @gray-darker;
    }
  }

  /* Hover state */
  .multiselect__option--highlight {
    background-color: @gray-light;
    opacity: .8;
  }

  /* Selected option */
  .multiselect__option--selected {
    background-color: @gray-lighter;
    color: @success;

    &.multiselect__option--highlight {
      &::after {
        background-color: inherit;
        color: @gray-darker;
      }
    }
  }

  /* Disabled option */
  .multiselect__option--disabled {
    background-color: @white !important;
    color: @gray-darker !important;
    opacity: .3;

    &.multiselect__option--highlight {
      &::after {
        background-color: inherit;
        color: @gray-dark;
      }
    }
  }

  /* Dropdown is opened */
  .multiselect--active {
    .multiselect__current,
    .multiselect__input,
    .multiselect__tags {
      border-radius: @border-radius;
    }

    .multiselect__input {
      margin: 0;
      padding: 0;
      width: auto !important;
    }
  }

  /* Dropdown is opened above */
  .multiselect--above {
    .multiselect__content-wrapper {
      margin-top: 0;
      margin-bottom: @spacer * .25;
    }
  }

  /* Flag icon */
  .flag-icon {
    margin-right: @spacer;
  }

  /* Overlay */
  .fshr-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: @black;
    opacity: .8;
  }
}

</style>
