var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.href)?_c('button',{staticClass:"fshr-button",class:[
    _vm.color && ("is-" + _vm.color),
    _vm.size && ("is-" + _vm.size),
    _vm.outlined && "is-outlined",
    _vm.selected && "is-selected",
    _vm.disabled && "is-disabled" ],attrs:{"disabled":_vm.disabled},on:{"click":_vm.onClick}},[(_vm.iconLeft)?_c('i',{staticClass:"fshr-button__iconLeft",class:[_vm.iconLeft && _vm.iconLeft]}):_vm._e(),_c('span',{staticClass:"fshr-button__label"},[_vm._t("default")],2),(_vm.iconRight)?_c('i',{staticClass:"fshr-button__iconRight",class:_vm.iconRight}):_vm._e()]):(!_vm.routerLink)?_c('a',{staticClass:"fshr-button",class:[
    _vm.color && ("is-" + _vm.color),
    _vm.size && ("is-" + _vm.size),
    _vm.outlined && "is-outlined",
    _vm.selected && "is-selected",
    _vm.disabled && "is-disabled" ],attrs:{"rel":"noopener","href":_vm.href,"disabled":_vm.disabled}},[(_vm.iconLeft)?_c('i',{staticClass:"fshr-button__iconLeft",class:[_vm.iconLeft && _vm.iconLeft]}):_vm._e(),_c('span',{staticClass:"fshr-button__label"},[_vm._t("default")],2),(_vm.iconRight)?_c('i',{staticClass:"fshr-button__iconRight",class:_vm.iconRight}):_vm._e()]):_c('router-link',{staticClass:"fshr-button",class:[
    _vm.color && ("is-" + _vm.color),
    _vm.size && ("is-" + _vm.size),
    _vm.outlined && "is-outlined",
    _vm.selected && "is-selected",
    _vm.disabled && "is-disabled" ],attrs:{"to":_vm.href,"disabled":_vm.disabled}},[(_vm.iconLeft)?_c('i',{staticClass:"fshr-button__iconLeft",class:[_vm.iconLeft && _vm.iconLeft]}):_vm._e(),_c('span',{staticClass:"fshr-button__label"},[_vm._t("default")],2),(_vm.iconRight)?_c('i',{staticClass:"fshr-button__iconRight",class:_vm.iconRight}):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }