<template>
  <button
    v-if="!href"
    class="fshr-button"
    :class="[
      color && `is-${color}`,
      size && `is-${size}`,
      outlined && `is-outlined`,
      selected && `is-selected`,
      disabled && `is-disabled`,
    ]"
    :disabled="disabled"
    @click="onClick"
  >
    <i
      v-if="iconLeft"
      class="fshr-button__iconLeft"
      :class="[iconLeft && iconLeft]" />
    <span class="fshr-button__label"><slot /></span>
    <i
      v-if="iconRight"
      class="fshr-button__iconRight"
      :class="iconRight" />
  </button>
  <a
    v-else-if="!routerLink"
    rel="noopener"
    class="fshr-button"
    :href="href"
    :disabled="disabled"
    :class="[
      color && `is-${color}`,
      size && `is-${size}`,
      outlined && `is-outlined`,
      selected && `is-selected`,
      disabled && `is-disabled`,
    ]"
  >
    <i
      v-if="iconLeft"
      class="fshr-button__iconLeft"
      :class="[iconLeft && iconLeft]" />
    <span class="fshr-button__label"><slot /></span>
    <i
      v-if="iconRight"
      class="fshr-button__iconRight"
      :class="iconRight" />
  </a>
  <router-link
    v-else
    class="fshr-button"
    :to="href"
    :disabled="disabled"
    :class="[
      color && `is-${color}`,
      size && `is-${size}`,
      outlined && `is-outlined`,
      selected && `is-selected`,
      disabled && `is-disabled`,
    ]"
  >
    <i
      v-if="iconLeft"
      class="fshr-button__iconLeft"
      :class="[iconLeft && iconLeft]" />
    <span class="fshr-button__label"><slot /></span>
    <i
      v-if="iconRight"
      class="fshr-button__iconRight"
      :class="iconRight" />
  </router-link>
</template>

<script>
const BUTTON_STYLES = [
  "primary",
  "success",
  "gray",
  "transparent"
];
const BUTTON_SIZES = [ "sm", "md", "lg" ];

/**
 * Component to perform some actions such as: redirecting to other page,
 * or submit a form.
 * @emits onClick when button is clicked
 */
export default {
  name: "vue-button",
  props: {
    /**
     * define a color of the button
     */
    color: {
      type: String,
      required: false,
      default: "primary",
      validator: (value) => (BUTTON_STYLES.includes(value) ? value : "primary"),
    },
    /**
     * define a size of the button
     */
    size: {
      type: String,
      required: false,
      validator: (value) => (BUTTON_SIZES.includes(value) ? value : "md"),
    },
    /**
     * define if button is selected
     */
    selected: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * define if button is outlined
     */
    outlined: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * define if button is disabled
     */
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * define icon class that will be displayed on
     * the left side of button's text
     */
    iconLeft: {
      type: String,
      required: false,
      default: null,
    },
    /**
     * define icon class that will be displayed on
     * the right side of button's text
     */
    iconRight: {
      type: String,
      required: false,
      default: null,
    },
    /**
     * to property defines where button should be
     * redirected if buttons behaives like <a> link
     */
    href: {
      type: String,
      required: false,
      default: null,
    },
    routerLink:{
      type: Boolean,
      required: false,
      default: false
    }
  },

  methods: {
    onClick(e) {
      e.preventDefault();
      e.stopPropagation();

      this.$emit("onClick");
    },
  },
};
</script>

<style scoped lang="less">
@import "../less/_buttons";
</style>
