<template>
  <div class="container is-main">
    <div class="wrapper">

      <Spinner v-if="is_loading"></Spinner>
      <div class="boxes-container" v-else>

        <div class="box box-filter">
          <div>
            <fieldset class="classic-input">
              <label>{{getTranslation('filtrovat_dle_nazvu')}}</label>
              <div class="classic-input-control">
                <input type="text" v-model="text_filter" :placeholder="getTranslation('napiste_co_hledate')">
                <i class="icon icon-close deleter" v-if="text_filter.length > 0" @click="text_filter = ''"></i>
              </div>
            </fieldset>

            <h2>{{getTranslation('s_jakym_potvrzenim_pojedu')}}:</h2>

            <ul class="filter-cat first-cat">
              <li v-for="val in getFiltersFromCategory(1)">
                <optionbox :id="val.id" :name="val.id" :label="val.label" :light="true" v-on:onToggle="changeFilters($event, val)"></optionbox>
              </li>
            </ul>

            <ul class="filter-cat second-cat">
              <li v-for="val in getFiltersFromCategory(2)">
                <optionbox :id="val.id" :name="val.id" :label="val.label" :light="true" v-on:onToggle="changeFilters($event, val)"></optionbox>
              </li>
            </ul>

            <v-select :options="available_sortings" label="label" v-model="sorting" :clearable="false" class="sorting-input"></v-select>

          </div>
        </div>


          <div class="box box-country" v-for="(country) in cuttedAndFilteredCountries" :key="country.id" :class="{'visible': country.hasOwnProperty('visible') && country.visible === true}">
            <div>
              <div class="box-heading">
                <span class="box-flag">
                  <i
                    class="checkbox-icon flag-icon" :class="[`flag-icon-${country.flag}`]"/>
                </span>
                <h2><a :href="country.link">{{ country.name }}</a></h2>
              </div>

              <div class="vaccination-label" v-if="country.vaccination_free_travel">
                <i class="icon-like"></i>{{getTranslation('ockovani_bez_omezeni')}}
              </div>

              <div class="conditions entry">
                <h3 class="conditions-heading">
                  {{getTranslation('tam')}}
                  <span class="condition-flag">
                    <i :class="getEntryIcon(country.entry.icon)"></i>
                  </span>
                </h3>

                <div v-html="country.entry.label_html"></div>
              </div>

              <div class="conditions exit">
                <h3 class="conditions-heading">
                  {{getTranslation('zpet')}}
                  <span class="condition-flag">
                    <i :class="getEntryIcon(country.exit.icon)"></i>
                  </span>
                </h3>
                <div v-html="country.exit.label_html"></div>
              </div>

              <div class="conditions transit" v-if="country.transit">
                <h3 class="conditions-heading">
                  {{getTranslation('transit')}}
                  <span class="condition-flag">
                    <i :class="getEntryIcon(country.transit.icon)"></i>
                  </span>
                </h3>
                <div v-html="country.transit.label_html"></div>
              </div>

              <div class="country-link">
                <a :href="country.link">{{getTranslation('vice')}} <i class="icon-arrow-right"></i> </a>
              </div>
            </div>
          </div>

        <div class="box no-records" v-if="isAllHidden">
          {{getTranslation('vasim_pozadavkum')}}
        </div>

      </div>

      <div class="show-another-wrapper" v-if="!is_loading && !isAllHidden">
        <Button icon-right="icon-arrow-down-slim" size="lg" v-on:onClick="expand()" v-if="isThereSomeUnvisibleCountries">{{getTranslation('dalsi_destinace')}}</Button>
        <div class="show-another-text" v-else>{{getTranslation('zobrazeny_vsechny_vysledky')}}</div>
      </div>


      <div class="banners-container" v-if="!is_loading && lang === 'cs'">
        <div class="banner" v-for="banner in banners" :style="backgroundGenerator(banner)">
          <div class="banner-inner">
            <header class="banner-heading">
              <h2>{{banner.title}}</h2>
              <p>{{banner.subtitle}}</p>
            </header>

            <footer>
              <Button size="md" icon-right="icon-arrow-right" :href="banner.link">{{ banner.linktitle }}</Button>
            </footer>
          </div>
          <div class="banner-placeholder">
            <h2>{{banner.title}}</h2>
            <p>{{banner.subtitle}}</p>
            <p v-html="banner.excerpt"></p>

            <Button size="md" icon-right="icon-arrow-right" :outlined="true" color="transparent" :href="banner.link" :router-link="banner.link === '/srovnavac/'">{{ banner.linktitle }}</Button>
          </div>
        </div>
      </div>

      <div class="brands-container" v-if="lang === 'cs' && brands.length > 0">
        <div class="brand" v-for="brand in brands">

          <div class="brand-image-wrapper">
            <img :src="brand.image" :alt="brand.title">
          </div>

          <p>{{brand.desc}}</p>
          <Button size="sm" color="primary" :outlined="true" icon-right="icon-arrow-right" :href="brand.cta_link">{{ brand.cta }}</Button>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import Checkbox from "@/components/Checkbox";
import Optionbox from "@/components/Optionbox";
import Button from "@/components/Button";
import vSelect from 'vue-select';
import Vue from 'vue';
import VueSmoothScroll from 'vue2-smooth-scroll';
Vue.use(VueSmoothScroll);
Vue.component('v-select', vSelect);
import '@/less/vue-select.css';

//import { wrapGrid } from 'animate-css-grid'
import axios from "axios";
import Spinner from "@/components/Spinner";
import Animate from "animate.css";
import getTranslation from "../mixins/translate";


export default {
  name: "HomeNew",
  components: { Spinner, Checkbox, Button, Optionbox },
  mixins: [ getTranslation ],
  mounted() {

    axios
      .get(this.apiUrlV2, {
        headers: {
          "X-API": this.apiToken,
        },
      })
      .then((response) => {

        if(response.status === 200){
          if(response.data.status && response.data.hasOwnProperty("payload")){
            setTimeout(()=> {
              this.countries = response.data.payload.countries;
              this.filters = response.data.payload.filters;
              this.banners = response.data.payload.banners;
              this.is_loading = false;
              /*setTimeout(()=>{
                const grid = document.querySelector(".boxes-container");
                wrapGrid(grid);
              },1)*/
            }, 300);
          }
        }

      })
      .finally(() => {
        this.loading = false;
      });


  },
  data(){
    return {
      show_only: 8,
      add_more: 4,
      focusing: false,
      is_loading: true,
      text_filter: "",
      filters:[],
      countries: [],
      banners: [],
      brands: []
    }
  },
  methods: {
    getFiltersFromCategory: function (catid){
      return this.filters.filter((val) => {
        return val.category === catid;
      });
    },
    getEntryIcon: function (status){
      switch(status){
        case 1: return ["icon","is-warning", "icon-info-circle"];
        case 2: return ["icon","is-error", "icon-close-circle"];
        case 3: return ["icon","is-success", "icon-checkmark-circle"];
      }
    },
    changeFilters: function (newVal, checkbox){
      this.$set(checkbox, 'value', newVal);
      this.$forceUpdate();
      this.focusFirstBox();
    },
    expand: function (){
      this.show_only = this.show_only + this.add_more;
    },
    backgroundGenerator: function (banner){
      return {'background-image': 'url("'  + banner.image + '")'};
    },
    focusFirstBox: function(){
      if(!this.focusing && window.innerWidth < 768 ){
        this.focusing = true;
        setTimeout(()=> {
          const myEl = document.querySelector(".boxes-container .box-country.visible");

          if(myEl !== null){
            this.$smoothScroll({
              scrollTo: myEl,
            }, 300);
          }
          this.focusing = false;

        },300);
      }

    }
  },
  props: {
    apiToken: {
      type: String,
      required: true,
    },
    apiUrlV2: {
      type: String,
      required: true,
    },
    translations: {
      type: Object,
      required: true,
    },
    lang: {
      type: String,
      required: true,
      default: "cs"
    },
    available_sortings: {
      type: Array,
      required: false,
      default: function (){
        return [
          {id: 0, label: 'Řadit dle oblíbených', sort: 'rating', direction: 'desc'},
          {id: 1, label: 'Řadit dle opatření', sort: 'sort_value', direction: 'asc'},
          {id: 2, label: 'Řadit dle abecedy', sort: 'name', direction: 'asc'}
        ]
      }
    },
    sorting: {
      type: Object,
      required: false,
      default: function (){
        return { id: 0, label: 'Řadit dle oblíbených', sort: 'rating', direction: 'desc'}
      }
    }
  },
  computed: {
    filterCountries: function () {
      let countries = this.countries;
      var {sort, direction} = this.sorting;

      countries = countries.sort((first, second)=>{
        if(sort === 'name' && "test".localeCompare !== "undefined"){
          return first[sort].localeCompare(second[sort], "cs");
        }else{
          if(first[sort] > second[sort]){
            return direction === 'asc' ? 1 : -1;
          }
          if(first[sort] < second[sort]){
            return direction === 'asc' ? -1 : 1;
          }
          return 0;
        }
      });

      var apliedFilters = this.filters.filter((filter)=>{
        return filter.hasOwnProperty('value') && filter['value'] === true;
      });

      countries.map((country)=>{
        country.remove = true;
        if(apliedFilters.length === 0) {country.remove = false; return country;}

        for(var i in apliedFilters){
          let filter = apliedFilters[i];
          for(var j in filter.tags_required){
            let tag = filter.tags_required[j];
            if(country.tags.includes(tag)){
              country.remove = false;
              return country;
            }else{
              country.remove = true;
            }
          }
        }
      });

      if(this.text_filter.length > 0){
        countries.map((country)=>{
          console.log("filtering by text");
          if (country.remove !== true && !country.name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(this.text_filter.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))) {
            country.remove = true;
          }
        });
      }

      return countries;
    },
    cuttedAndFilteredCountries: function (){
      let countries = JSON.parse(JSON.stringify(this.filterCountries));
      let alreadyVisible = 0;
      countries.map((val, index) =>{
        if(alreadyVisible >= this.show_only){
          val.visible = false;
        }else{
          if(val.remove === false) {
            val.visible = true;
            alreadyVisible++;
          }else{
            val.visible = false;
          }
        }
      });
      return countries;
    },
    isThereSomeUnvisibleCountries: function (){
      let countries = this.cuttedAndFilteredCountries;
      let res = countries.reduce((acc, val)=>{

        if(val.visible === false && val.remove === false){
          acc++;
        }
        return acc;
      },0);
      return res;
    },
    isAllHidden: function(){
      let countries = this.cuttedAndFilteredCountries;
      let res = countries.reduce((acc, val)=>{
        if(val.remove !== true){
          acc++;
        }
        return acc;
      },0);
      return (res === 0);
    }
  }
};
</script>

<style lang="less">
@import "../less/variables.less";
@import "../less/mixins.less";



@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

.brands-container{
  display: flex;
  margin-top: @spacer * 3;
  flex-wrap: wrap;
  justify-content: center;

  .brand{
    padding: @spacer;
    box-sizing: border-box;
    text-align: center;
    box-shadow: @box-shadow;

    border-radius: @border-radius;
    flex-basis: 100%;
    margin: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;



    @media screen and (min-width:768px) and (max-width: 1000px){
      flex-basis: calc( (100% / 2) - 7.5px);
      margin: 0px 15px 0px 0px;

      &:nth-child(2n){
        margin-right: 0px;
      }

    }

    @media screen and (min-width:1000px){
      flex-basis: calc( (100% / 4) - 11.25px);
      margin: 0px 15px 0px 0px;

      &:last-of-type{
        margin-right: 0px;
      }
    }

    p{
      margin-top: 10px;
    }

    a{
      margin-top: auto;
      width: fit-content;
    }

    .brand-image-wrapper{
      width: 160px;
      height: 40px;
      display: inline-block;

      img{
        width: 100%;
        height: 100%;
      }
    }
  }
}

.sorting-input{
  cursor: pointer;
}

.cards-enter-active, .cards-leave-active {
  transition: all 1s;
}
.cards-enter, .cards-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: scale(0);
}

.container {
  .container-styles; // mixin for container

  &.is-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100px;

    @media (min-height:768px) {
      min-height: 200px;
    }
  }
}

.banners-container{
  display: flex;
  flex-wrap: wrap;

  width: 100%;
  justify-content: center;
  margin-top: 3*@spacer;

  .banner{

    background-color: @gray-light;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    box-sizing: border-box;
    height: 0;
    flex: 0 1 100%;
    padding-bottom: 100%;
    margin-bottom: @spacer;
    position: relative;
    overflow: hidden;
    transition: box-shadow 300ms ease-in-out;
    border-radius: @border-radius;

    @media screen and (min-width: 1000px){
      flex: 0 1 calc((100% / 3) - 2*@spacer)!important;
      padding-bottom: calc((100% / 3) - 2*@spacer)!important;
      margin-right: @spacer;

      &:nth-child(3n){
        margin-right: 0px;
      }

      &:nth-child(2n){
        margin-right: @spacer!important;
      }
    }

    @media screen and (min-width: 768px){
      flex: 0 1 calc((100% / 2) - @spacer);
      padding-bottom: calc((100% / 2) - @spacer);
      margin-right: @spacer;
      margin-bottom: @spacer;
      &:nth-child(2n){
        margin-right: 0px;
      }
    }


    &:hover{
      box-shadow: @box-shadow;

      .banner-placeholder{
        top: 0px;

        ul {
          list-style: none;
          padding: 0px;
          margin: 0px;
        }
      }
      .banner-inner{
        left: -100%;
      }
    }

    .banner-placeholder{
      position: absolute;
      top: 100%;
      left: 0px;
      right: 0px;
      height: 100%;
      background-color: @secondary-transparent;
      transition: all 300ms ease-in-out;
      z-index: 99;
      padding: @spacer;
      color: @white;
      text-align: center;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      h2{
        margin-top: 0px;
      }
    }


    .banner-inner{
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      padding: @spacer * 1.2;
      z-index: 9;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      transition: all 300ms ease-in-out;
      box-sizing: border-box;

      h2{
        margin: 0px 0px @spacer*0.5 0px;
        font-size: @font-size-06;
        color: @secondary;
      }
      p{
        margin: 0px;
        font-size: @font-size-05;
        color: @secondary;
      }
    }

  }

}

.show-another-wrapper{
  display: flex;
  justify-content: center;
  margin-top: @spacer * 2;
}

.boxes-container{
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: @spacer;
  grid-row-gap: @spacer;
  margin-top: 0px;
  z-index: 99;
  position: relative;

  @media (min-width:768px) {
    grid-template-columns: 1fr 1fr;
    margin-top: -100px;
  }

  @media (min-width:1200px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .box{
    padding: @spacer;
    box-shadow: @box-shadow;
    border-radius: @border-radius;
    background-color: @white;

    &.no-records{
      grid-column-end: 5;
      grid-column-start: 2;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: @font-size-04;
      background-color: transparent;
      box-shadow: none;
      padding-left: 20px;
      padding-right: 20px;
      font-weight: bold;
    }

    &.box-country{
      display: none;
      &.visible{
        display: block;
        animation: pulse 500ms ease-in-out;
      }

      > div{
        display: flex;
        flex-direction: column;
        height: 100%;
      }
    }

    .vaccination-label{
      padding: @spacer*0.2 @spacer*0.5;
      background-color: @success;
      color: white;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: @font-weight-bold;
      width: fit-content;
      display: flex;
      align-items: center;
      border-radius: @border-radius;
      margin-bottom: @spacer;

       i {
         margin-right: 7px;
         font-weight: 100;
       }
    }

    .box-heading{
      display: flex;
      align-items: center;
      padding: 0 0 @spacer 0;
      border-bottom: 1px solid @gray-light;
      margin-bottom: @spacer * 0.8;

      h2{
        margin: 0;
        font-size: @font-size-03;
        a{
          color: @black;
          text-decoration: none;
        }
      }

      .box-flag{
        margin-right: 15px;
        font-size: @font-size-05;
      }
    }

    .conditions{

      margin-bottom: @spacer * 1.5;

      .conditions-heading{
        font-size: @font-size-03;
        display: flex;
        align-items: center;
        margin-bottom: 0.2 * @spacer;
        margin-top: 0;

        .condition-flag{
          margin-left: (@spacer * 0.5);
          font-size: @font-size-05;

          .icon.is-error{
            color: @danger;
          }

          .icon.is-warning{
            color: @warning;
          }

          .icon.is-success{
            color: @success;
          }
        }
      }

      ul{
        list-style: none;
        padding: 0px;
        margin: 0px;

        li{
          padding-left: @spacer * 1.2;
          position: relative;
          &:before{
            content: "\e901";
            font-family: 'icomoon' !important;
            position: absolute;
            left: 2px;
            font-size: 0.8 * @font-size-00;
            font-weight: @font-weight-bold;
          }
        }
      }
    }

    .country-link{
      display: flex;
      margin-top: auto;
      justify-content: flex-end;
      text-transform: uppercase;
      font-weight: @font-weight-bold;
      a {
        text-decoration: none;
      }
      .icon-arrow-right{
        font-size: @font-size-00;
        margin-left: @spacer * 0.3;
      }
    }

    &.box-filter{
      display: none;

      h2{
        font-size: @font-size-05;
        margin-top: 0;
        margin-bottom: @spacer;
        font-weight: @font-weight-bold;
        line-height: 1.2;
      }



      .filter-cat{
        padding: 0px;
        list-style: none;

        &.first-cat{
          border-bottom: 1px solid @gray-light;
        }

        li{
          margin: @spacer*0.7 0px;
        }
      }
    }
  }
}

.show-another-text{
  font-weight: @font-weight-bold;
  text-transform: uppercase;
  font-size: @font-size-04;
  margin-top: @spacer;
  margin-bottom: @spacer;
}


.vs__dropdown-toggle{
  padding: 5px 0px 9px;
}

.title {
  .title-styles; // mixin for titles
  margin: 0 0 (@spacer * 2.25);
}

.subtitle {
  margin: 0 0 @spacer;
  line-height: @line-height-04;
  font-size: @font-size-04;
}

  .classic-input {
    padding: 0 0;
    border: none;
    margin-bottom: 15px;

    input {
      line-height: 1.4;
      font-size: 1em;
      padding: 0px 12px;
      width: 100%;
      box-sizing: border-box;
      border: 1px solid rgba(60,60,60,.26);
      border-radius: 0px;
      height: 41px;

      &:focus{
        outline: none;
      }
    }

    .classic-input-control{
      position: relative;
      .deleter{
        position: absolute;
        color: #9d9d9d;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        cursor: pointer;
        &:hover{
          color: @primary-light;
        }
      }
    }

    label{
      margin-bottom: 10px;
      display: block;
      font-size: 1.25rem;
      font-weight: @font-weight-bold;
    }
  }

</style>

