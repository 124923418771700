<template>
  <div class="wrapper">
    <ul class="list">
      <li
        v-for="(tab, index) in tabs"
        :key="index"
        class="item"
        :class="{ 'item--isActive': index === activeIndex }"
        @click="onChange(index)"
      >
        {{ tab }}
      </li>
    </ul>
  </div>
</template>

<script>
/**
 * @emits onChange when new tab is selected
 */
export default {
  props: {
    /**
     * define tab header items
     */
    tabs: {
      type: Array,
      required: true,
    },
    defaultIndex: {
      type: Number,
      required: false,
    },
  },

  data() {
    return {
      activeIndex: this.defaultIndex || 0,
    };
  },

  methods: {
    onChange(tabIndex) {
      if (this.activeIndex !== tabIndex) {
        this.activeIndex = tabIndex;

        this.$emit("onChange", tabIndex);
      }
    },
  },
};
</script>

<style scoped lang="less">
@import "../less/variables";

.wrapper {
  padding-bottom: @spacer; // For arrow in active tab
  overflow-x: auto;
  overflow-y: hidden;
}

.list {
  display: flex;
  margin: 0;
  padding: 0;
  border-radius: @border-radius;
  background-color: @gray-lighter;
  list-style-type: none;
}

.item {
  position: relative;
  display: flex;
  align-items: center;
  padding: (@spacer * .75) @spacer;
  min-height: 48px;
  border-right: 1px solid @gray-dark;
  background-color: @gray-lighter;
  text-align: center;
  line-height: @line-height-03;
  white-space: nowrap;
  font-size: @font-size-03;
  font-weight: @font-weight-thin;
  color: @primary;
  cursor: pointer;
  box-sizing: border-box;

  &:hover {
    background-color: @primary;
    color: @white;
    transition: 300ms;
  }

  &:first-child {
    border-radius: @border-radius 0 0 @border-radius;
  }
}

.item--isActive {
  background-color: @primary;
  color: @white;
  cursor: default;

  &::after {
    content: "";
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    display: block;
    margin: auto;
    width: 0;
    height: 0;
    border-top: 10px solid @primary;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
  }
}
</style>
