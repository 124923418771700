<template>
  <div class="container is-main">
    <router-link to="/">{{getTranslation("zpet_na_zakladni_prehled")}}</router-link>
    <Spinner
      v-if="loading"/>
    <div
      v-else
      class="wrapper">
      <section
        class="search-form"
      >
        <header class="search-form__header">
          <h2
            class="title l-title-custom"
            v-html="getTranslation('header_title')"
          />
          <h3
            class="subtitle l-title-custom"
            v-html="getTranslation('header_subtitle')"
          />
        </header>
        <ButtonFilters
          :options="filterOptions"
          :selectedOptions="selectedFilters"
          @onChange="onChangeSelectedFilter"
        />

        <SearchBar
          :options="searchBarOptions"
          :selectedOptions="searchBarValue"
          @onChange="onChangeSearchBar"
          :translations="translations"
        />

        <CountrySelection
          @onCountryChange="onCountryChange"
          @onTabChange="onTabChange"
          :activeTab="this.activeTab"
          :selectedCountries="this.selectedCountries"
          :visibleCountries="visibleCountries"
          :countryCategories="payload.country_categories"
        />

        <div class="search-button-wrapper">
          <Button
            class="search-button"
            iconRight="icon-arrow-right"
            @onClick="onClickShowResults"
            :disabled="selectedCountries && selectedCountries.length === 0"
            v-html="getTranslation('button_show_restrictions')"
          />
        </div>
      </section>

      <section
        class="search-result"
        v-if="selectedCountries && selectedCountries.length > 0 && areTablesVisible"
      >
        <div class="content">
          <GeneralTables
            :tables="payload.restrictions_categories"
            :countries="payload.countries"
            :restrictions="payload.restrictions"
            :selectedCountries="selectedCountries"
            :translations="translations"
          />
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Button from "../components/Button.vue";
import SearchBar from "../components/SearchBar.vue";
import GeneralTables from "../components/GeneralTables.vue";
import Spinner from "../components/Spinner.vue";

import ButtonFilters from "../components/ButtonFilters.vue";
import CountrySelection from "../components/CountrySelection.vue";
import { isCountryAvailable, refineCountries } from "../helpers/countries";
import addQuery from "../mixins/addQuery";
import getTranslation from "../mixins/translate";

export default {
  name: "container",
  components: {
    Button,
    ButtonFilters,
    CountrySelection,
    SearchBar,
    Spinner,
    GeneralTables,
  },
  mounted() {
    axios
      .get(this.apiUrl, {
        headers: {
          "X-API": this.apiToken,
        },
      })
      .then((response) => {
        this.payload = response.data;
      })
      .finally(() => {
        this.loading = false;
      });
  },

  props: {
    apiToken: {
      type: String,
      required: true,
    },
    apiUrl: {
      type: String,
      required: true,
    },
    translations: {
      type: Object,
      required: true,
    },
  },
  mixins: [ addQuery, getTranslation ],
  data() {

    var selectedFilters, selectedCountries;
    if(this.$route.query.hasOwnProperty('countries')){
      if(typeof this.$route.query.countries === 'object'){
        selectedCountries = this.$route.query.countries;
      }else{
        selectedCountries = [this.$route.query.countries];
      }
    }else{
      selectedCountries = [];
    }

    selectedCountries.map(function (val, index){
      selectedCountries[index] = parseInt(val);
    });

    if(this.$route.query.hasOwnProperty('filters')){
      if(typeof this.$route.query.filters === 'object'){
        selectedFilters = this.$route.query.filters;
      }else{
        selectedFilters = [this.$route.query.filters];
      }
    }else{
      selectedFilters = [];
    }


    selectedFilters.map((val, index)=>{
      selectedFilters[index] = parseInt(val);
    });

    return {
      loading: true,
      areTablesVisible: selectedCountries.length > 0, // deeplink shows results
      selectedFilters,
      payload: null,
      selectedCountries,
      activeTab: parseInt(this.$route.query.tab, 10) || 0,
    };
  },

  methods: {
    /** when user clicks on button to give a table results  */
    onClickShowResults() {
      this.areTablesVisible = true;
    },

    /**
    * triggers when search bar change value
    * @param {object} value - selected country
    */
    onChangeSearchBar(value) {
      this.areTablesVisible = false;

      if (value) {
        this.selectedCountries = value.map((item) => item.id);
        this.$router.push(
          {
            path: "/srovnavac/",
            query: {
              tab: this.activeTab,
              countries: this.selectedCountries,
              filters: this.selectedFilters
            }
          }
        );

      }
    },

    /** when we change which filter is on/off */
    onChangeSelectedFilter(filterId) {
      const checked = this.selectedFilters.includes(filterId);

      if (checked) {
        this.selectedFilters = this.selectedFilters.filter((item) => item !== filterId);
      } else {
        this.selectedFilters.push(filterId);
      }

      /** find which country is disabled or selected ''> SEPERATE LOGIC ??? */
      const filter = this.payload.filters.find((f) => `${f.id}` === `${filterId}`);
      const disabledCountries = this.payload.countries
        .filter((country) => !isCountryAvailable(country, filter))
        .map((country) => country.iso);

      // unselect disabled items
      this.selectedCountries = this.selectedCountries
        .filter((code) => disabledCountries.indexOf(code) === -1);

      this.$router.push(
        {
          path: "/srovnavac/",
          query: {
            tab: this.activeTab,
            countries: this.selectedCountries,
            filters: this.selectedFilters
          }
        }
      );
    },

    /** when new country is selected from the list */
    onCountryChange(code) {
      this.areTablesVisible = false;

      if (!this.selectedCountries.includes(code)) {
        this.selectedCountries.push(code);
      } else {
        this.selectedCountries = this.selectedCountries.filter((item) => item !== code);
      }

      setTimeout(()=>{
        this.$router.push(
          {
            path: "/srovnavac/",
            query: {
              tab: this.activeTab,
              countries: this.selectedCountries,
              filters: this.selectedFilters
            }
          }
        );
      },100)
    },

    onTabChange(value) {
      this.activeTab = value;
      this.$router.push(
        {
          path: "/srovnavac/",
          query: {
            tab: value,
            countries: this.selectedCountries,
            filters: this.selectedFilters
          }
        }
      );
      //this.setQueryParam({ name: "tab", value });
    },
  },

  computed: {
    filterOptions() {
      if (this.loading) {
        return null;
      }
      return this.payload.filters.map((filter) => {
        const matchedCountries = this.payload.countries
          .filter(
            (country) => (this.selectedFilters.length > 0 ? this.selectedFilters
              .every((filterId) => {
                const selectedFilter = this.payload.filters.find((f) => `${f.id}` === `${filterId}`);
                return isCountryAvailable(country, selectedFilter);
              }) : true),
          );

        const isDisabled = !matchedCountries
          .some((country) => isCountryAvailable(country, filter));

        return {
          label: filter.name,
          value: filter.id,
          $isDisabled: isDisabled,
        };
      });
    },

    /** generate all countries that will be visible on result list */
    visibleCountries() {
      if (this.loading) {
        return null;
      }
      const categoryId = this.payload.country_categories[this.activeTab || 0].id;
      return refineCountries(this.payload.countries, this.payload.filters, this.selectedFilters)
        .filter(
          (country) => country.category.includes(categoryId),
        );
    },

    /** generate all options that search bar will display in dropdown */
    searchBarOptions() {
      if (this.loading) {
        return null;
      }
      return refineCountries(this.payload.countries, this.payload.filters, this.selectedFilters);
    },

    /**
     * get selected countries for search bar
     */
    searchBarValue() {
      if (this.loading) {
        return null;
      }
      return this.selectedCountries
        .map((value) => (this.payload.countries || []).find((item) => item.id === value));
    },
  },
};
</script>

<style scoped lang="less">
@import "../less/variables.less";
@import "../less/mixins.less";

.container {
  .container-styles; // mixin for container

  &.is-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100px;

    @media (min-height:768px) {
      min-height: 200px;
    }
  }
}

.search-form {
  margin-bottom: @spacer * 2;

  @media (min-width:768px) {
    margin-bottom: @spacer * 4;
  }
}

.search-form__header {
  margin-bottom: @spacer;
  text-align: center;
}

.title {
  .title-styles; // mixin for titles
  margin: 0 0 (@spacer * 2.25);
}

.subtitle {
  margin: 0 0 @spacer;
  line-height: @line-height-04;
  font-size: @font-size-04;
}

/* Table */
.content {
  margin-bottom: @spacer * 2;

  @media (min-width:768px) {
    margin-bottom: @spacer * 4;
  }
}

/* Search button */
.search-button-wrapper {
  text-align: center;
}

.search-button {
  min-width: 220px;
}
</style>
