<template>
  <label
    :for="id"
    :class="[
      `checkbox`,
      disabled && `is-disabled`
    ]">
    <input
      class="input"
      type="checkbox"
      :id="id"
      :name="name"
      @change="updateChecked"
      :checked="checked"
      :value="value"
      :disabled="disabled"
    >
    <span class="label" :class="{'label_light': light}">
      <i
        class="checkbox-icon flag-icon"
        v-if="flag"
        :class="[`flag-icon-${flag}`]"/>
      {{ label }}
    </span>
  </label>
</template>

<script>
/**
   * Component for checkboxes
   * @emits onToggle when checkbox is clicked
   */
export default {
  props: {
    checked: {
      type: Boolean,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    id: {
      type: String | Number,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    value: {
      type: String | Number,
      required: false,
      default: "",
    },
    label: {
      type: String,
      required: false,
      default: "",
    },
    flag: {
      type: String,
      required: false,
      default: "",
    },
    light: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    updateChecked(e) {
      if (!this.disabled) {
        e.preventDefault();
        e.stopPropagation();
        /* eslint-disable */
        this.$emit("onToggle", e.target.checked, this.value); // emit the checkbox value
      }
    },
  },
};
</script>

<style scoped lang="less">
@import "../less/variables";
@import "../less/mixins";

.checkbox {
  position: relative;
  display: inline-block;
  padding-left: @spacer * 2;
  cursor: pointer;

  &:hover {
    opacity: .7;
  }

  &.is-disabled {
    color: @gray-darker;
    cursor: not-allowed;

    &:hover {
      opacity: 1;
    }
  }
}

.input {
  position: absolute;
  top: 18px;
  left: 10px;
  width: 1px;
  height: 1px;
  opacity: 0;
  z-index: -1;
  pointer-events: none;

  &:checked {
    + .label {
      color: @success;

      &::before {
        content: @icon-checkmark;
        border-color: @success;
        background-color: @success;
      }
    }

    &:disabled {
      + .label {
        &::before {
          background-color: @gray-light;
        }
      }
    }
  }

  &:disabled {
    + .label {
      &::before {
        border-color: @gray-light;
      }

      .flag-icon {
        filter: grayscale(100%);
        opacity: .5;
      }
    }
  }
}

.label {
  display: flex;
  align-items: center;
  line-height: @line-height-03;
  font-size: @font-size-03;
  font-weight: @font-weight-semibold;

  &.label_light{
    font-weight: @font-weight-base;
  }
  &::before {
    .iconfont-styles; // mixin for iconfont
    content: "";
    position: absolute;
    top: 2px;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    border: 1px solid @gray-dark;
    border-radius: 3px;
    font-size: 12px;
    color: @white;
    box-sizing: border-box;
  }
}

/*
Checkboxes with flags
*/
.checkbox-icon {
  margin-right: @spacer;
  width: 30px;
  height: 20px;
}
</style>
