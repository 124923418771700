const createTableContent = (table, countries, restrictions, showFooter = false) => ({
  subHeader: table.name || "",
  headerRow: {
    title: table.subtitle || "",
    items: [
      ...(countries.map(({ name, url }) => ({
        label: name,
        url,
      }))),
    ],
  },
  bodyRows: [
    ...(restrictions
      .filter((r) => {
        return r.category_id === table.id
      })
      .map((restriction) => {

        return [
          { label: restriction.name },
          ...(countries.map((country) => {
          const coutryRestictionItem = country.restrictions
            .find((cr) => cr.id === restriction.id);
          const iconId = coutryRestictionItem.icon;

          let icon = "";

          if (iconId === 0) {
            icon = "icon is-success icon-checkmark-circle";
          }

          if (iconId === 1) {
            icon = "icon is-warning icon-info-circle";
          }

          if (iconId === 2) {
            icon = "icon is-error icon-close-circle";
          }

          return {
            icon,
            label: coutryRestictionItem.value_text,
          };
        })),
      ]})
    ),
  ],
  ...(showFooter ? {
    footerRows: [
      [
        { label: "", url: "" },
        ...(countries.map((country) => ({
          url: country.url,
        }))),
      ],
    ],
  } : {}),
});

module.exports = {
  createTableContent,
};
