export default {
  methods: {
    /** set new query in router, one or multiple query params
     * @param {[{name: string, value: string|number }]} values
     */
    setQueryParam(values) {
      const query = {
        ...this.$route.query,
      };

      if (Array.isArray(values)) {
      // eslint-disable-next-line no-restricted-syntax
        for (const { name, value } of values) {
          if (name) {
            query[name] = Array.isArray(value) ? value.filter(Boolean).join(",") : value;

            if (query[name] === "") {
              delete query[name];
            }
          }
        }
      } else {
        const { name, value } = values;
        query[name] = Array.isArray(value) ? value.filter(Boolean).join(",") : value;

        if (query[name] === "") {
          delete query[name];
        }
      }

      this.$router.push({
        name: "Home",
        query,
      });
    },
  },
};
