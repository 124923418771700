const isCountryAvailable = (country, filter) => {
  if (!filter || !country) {
    return false;
  }
  const { restriction_required: required, restriction_denied: denied, countries } = filter;

  return (
    required && required.length > 0 ? required.some(
      (r) => country.restrictions.findIndex((cr) => cr.id === r && cr.value > 0) !== -1,
    ) : true
  ) && (
    denied && denied.length > 0 ? !denied.some(
      (r) => country.restrictions.findIndex((cr) => cr.id === r && cr.value > 0) !== -1,
    ) : true
  ) && (
    countries && countries.length > 0 ? countries.includes(country.id) : true
  );
};

const refineCountries = (countries, filters, selectedFilters) => countries
  .map((country) => {
    const isDisabled = selectedFilters.length > 0 ? !selectedFilters.every((filterId) => {
      const filter = filters.find((f) => `${f.id}` === `${filterId}`);
      return isCountryAvailable(country, filter);
    }) : false;

    return {
      ...country,
      $isDisabled: isDisabled,
    };
  });

module.exports = {
  isCountryAvailable,
  refineCountries,
};
