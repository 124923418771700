import Vue from "vue";
import router from "./router";
import store from "./store";
import "normalize.css";
import "flag-icon-css/css/flag-icon.min.css";
import "./less/global.less";
import "./less/homepage.less";
import "./less/faq.less";
import "./helpers/slideupdown.js";

//import Accordion from 'accordion-js';
//import 'accordion-js/dist/accordion.min.css';

Vue.config.productionTip = false;

// eslint-disable-next-line no-new
new Vue({
  el: "#app",
  router,
  store,
  components: {
    App: () => import("./App"),
  },
});


/*document.addEventListener("DOMContentLoaded", function() {
  new Accordion('.accordion-container');
});*/


